import { ReactElement } from 'react';
import { Box, Typography, Link, Theme, Button } from '@mui/material';

import {
  Icon24InfoScience,
  Icon24InfoArt,
  Icon24InfoSports,
  Icon24InfoMusic,
  Icon24InfoCelebrate,
  Icon24InfoBooks,
  Icon24InfoMountains,
} from '@care/react-icons';
import { SxClassProps } from '@/types/common';
import { AmplitudeEvent, trackAmplitudeEvent } from '@/lib/analyticsHelper';

const classes: SxClassProps = {
  wrapper: {
    paddingBottom: {
      xs: 5,
      md: 8,
    },
    paddingX: {
      xs: 3,
      lg: 10,
    },
  },
  categoriesContainer: {
    display: 'flex',
    justifyContent: { xs: 'flex-start', sm: 'center' },
    flexDirection: { xs: 'row', sm: 'colmn' },
    flexWrap: { xs: 'no-wrap', sm: 'wrap' },
    overflowX: { xs: 'scroll', sm: 'auto' },
    margin: '0 auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  categoriesLink: (theme: Theme) => ({
    color: theme.palette.care?.navy[900],
    textDecoration: 'none',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'none',
    },
  }),
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  item: (theme: Theme) => ({
    display: 'flex',
    flexDirection: { xs: 'column' },
    alignItems: 'center',
    justifyContent: 'center',
    height: '104px',
    borderRadius: 4,
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    cursor: 'pointer',
    paddingY: 2,
    marginRight: {
      xs: 1,
      sm: 2,
      md: 1,
      lg: 2,
    },
    marginBottom: {
      xs: 2,
      sm: 1.5,
    },
    marginTop: {
      xs: 1,
      sm: 2,
    },
    marginLeft: {
      xs: 0.5,
      sm: 0,
    },
    '&:hover': {
      boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.15)',
    },
    width: {
      xs: '104px',
      sm: '125px',
      md: '120px',
      lg: '140px',
    },
    svg: {
      color: theme.palette.care?.navy[900],
      height: '48px',
      path: {
        color: theme.palette.care?.navy[900],
        fill: theme.palette.care?.navy[900],
      },
    },
  }),
  categoryHeadline: {
    textAlign: {
      xs: 'left',
      sm: 'center',
    },
    fontSize: {
      xs: 'body1.fontSize',
      sm: 'h2.fontSize',
    },
  },
};

interface CategoriesDisplayInfo {
  displayName: string;
  url: string;
  categoryIcon: ReactElement | undefined;
}

const categoryInfo: CategoriesDisplayInfo[] = [
  {
    displayName: 'STEM',
    url: '/explore/d/stem',
    categoryIcon: <Icon24InfoScience size={48} />,
  },
  {
    displayName: 'Art',
    url: '/explore/d/art',
    categoryIcon: <Icon24InfoArt size={48} />,
  },
  {
    displayName: 'Sports',
    url: '/explore/d/sports',
    categoryIcon: <Icon24InfoSports size={48} />,
  },
  {
    displayName: 'Music',
    url: '/explore/d/music',
    categoryIcon: <Icon24InfoMusic size={48} />,
  },
  {
    displayName: 'Dance',
    url: '/explore/d/dance',
    categoryIcon: <Icon24InfoCelebrate size={48} />,
  },
  {
    displayName: 'Academics',
    url: '/explore/d/academic',
    categoryIcon: <Icon24InfoBooks size={48} />,
  },
  {
    displayName: 'Adventure',
    url: '/explore/d/outdoor-and-adventure',
    categoryIcon: <Icon24InfoMountains size={48} />,
  },
];

function CategoryTiles() {
  const onCategoryClick = (category: string) => {
    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, {
      category,
      feature_name: 'Category tile',
    });
  };

  return (
    <Box sx={classes.wrapper}>
      <Box pt={{ xs: 1, sm: 2, md: 5 }} pb={{ xs: 1 }} px={{ xs: 1, sm: 3 }}>
        <Typography variant="h2" sx={classes.categoryHeadline}>
          Popular categories
        </Typography>
      </Box>
      <Box sx={classes.categoriesContainer}>
        {categoryInfo.map((option, index) => (
          <Link
            key={option.url}
            href={option.url}
            sx={classes.categoriesLink}
            onClick={() => onCategoryClick(option.displayName)}>
            <Button tabIndex={index} sx={classes.item} data-testid={option.displayName}>
              {option.categoryIcon}
              <Box>
                <Typography variant="body2" color="secondary" textAlign="center">
                  {option.displayName}
                </Typography>
              </Box>
            </Button>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default CategoryTiles;
