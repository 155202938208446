import { ChangeEvent, useState, useEffect, useRef, useMemo } from 'react';
import { useRouter } from 'next/router';
import { orderBy } from 'lodash-es';
import { TextField, Modal, Select } from '@care/react-component-lib';
import {
  Icon24UtilityChevron,
  Icon24UtilityChevronLeft,
  Icon24InfoLocation,
  Icon24UtilitySearch,
} from '@care/react-icons';
import {
  Button,
  FormControl,
  Grid,
  Theme,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { SxClassProps } from '@/types/common';
import { ACTIVITY_CATEGORY_TITLES, ActivityCategory } from '@/constants';
import { AmplitudeEvent, trackAmplitudeEvent } from '@/lib/analyticsHelper';
import { seoActivityCategoryLinkSections_seoActivityCategoryLinkSections_SEOActivityCategoryLinkSectionsSuccess as SEOActivityCategoryLinkSections } from '@/__generated__/seoActivityCategoryLinkSections';

import { useResponsive } from '../../hooks/useResponsive';

const classes: SxClassProps = {
  container: (theme: Theme) => ({
    backgroundColor: theme.palette.care.grey[100],
    padding: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0),
    },
  }),
  locationSelect: (theme: Theme) => ({
    backgroundColor: theme.palette.care.white,
    borderRadius: '12px',

    '& .MuiInputBase-root': {
      borderRadius: '12px !important',
    },

    '& .MuiInputBase-input': {
      border: `solid 1px ${theme.palette.care.grey[300]} !important`,
      borderRadius: '12px !important',
      fontWeight: '400 !important',
    },
  }),
  searchButton: (theme: Theme) => ({
    height: '40px',
    fontSize: '1.3125rem',
    fontWeight: '700',
    borderRadius: '24px',
    padding: 3,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1.3125rem',
      padding: 3,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 1,
      width: '100%',
    },
  }),
  inputSelection: {
    width: '100%',
    backgroundColor: 'care.white',
  },
  selectionOne: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      borderRadius: '30px 0 0 30px',
      minHeight: '64px',
    },
    '&:hover': {
      backgroundColor: 'care.white',
    },
  }),
  selectionTwo: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      borderRadius: '0',
      borderLeft: 'none',
      minHeight: '64px',
      '&:hover': {
        backgroundColor: 'care.white',
      },
    },
  }),
  selectionThree: (theme: Theme) => ({
    width: '100%',
    [theme.breakpoints.up('md')]: {
      '& > div:nth-of-type(1)': {
        borderRadius: '0 30px 30px 0',
        borderLeft: 'none',
        minHeight: '64px',
      },
    },
    [theme.breakpoints.up('xs')]: {
      '& > div:nth-of-type(1)': {
        minHeight: '64px',
      },
    },
  }),
  searchActivity: {
    width: '100%',
    '& > div:nth-of-type(1)': {
      minHeight: '64px',
    },
  },
};

export type CampsAndActivitiesSearchProps = {
  userGeoInfo: string;
  activityCategoryLinkSections: SEOActivityCategoryLinkSections | null;
};

interface CategoryOption {
  id: ActivityCategory;
  title: string;
  subCategories: {
    name: string;
    url: string;
  }[];
}

const ZipCodeRegex = /^\d{0,5}$/;

const capitalizeFirstLetter = (string: string) => {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const prefillZipCode = (userGeoInfo: string): string => {
  const zipCodeRegex = /zip=(\d{5})/;
  const match = zipCodeRegex.exec(userGeoInfo);
  if (match?.[1]) {
    return match[1];
  }
  return '';
};

const CampsAndActivitiesSearch = ({
  userGeoInfo,
  activityCategoryLinkSections,
}: CampsAndActivitiesSearchProps) => {
  const router = useRouter();
  const { isSmallViewport, isMediumViewport } = useResponsive();
  const zipInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [zipCodeValue, setZipCodeValue] = useState('');
  const [openActivitySearchModal, setOpenActivitySearchModal] = useState(false);

  const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<{ name: string; url: string }[]>([]);
  const [subCategoryToCategoryMap, setSubCategoryToCategoryMap] = useState<{
    [key: string]: string;
  }>({});

  const prefilledZipCode: string = useMemo(() => prefillZipCode(userGeoInfo), [userGeoInfo]);
  const [zip, setZip] = useState({
    zipCode: prefilledZipCode,
  });

  const handleOpenModal = () => {
    setOpenActivitySearchModal(true);
  };

  const handleCloseModal = () => {
    setOpenActivitySearchModal(false);
  };

  const handleCategoryChange = (event: SelectChangeEvent<unknown>) => {
    const selectedCategoryId = event.target.value as string;
    const selectedCategoryObj = categoryOptions.find(
      (category) => category.id === selectedCategoryId
    );
    setSelectedCategory(selectedCategoryId);
    setSubCategoryOptions(selectedCategoryObj?.subCategories ?? []);
    setSelectedSubCategory('');
  };

  const handleSubCategoryChange = (event: SelectChangeEvent<unknown>) => {
    const selectedSubCategoryVal = event.target.value as string;
    setSelectedSubCategory(selectedSubCategoryVal);

    const relatedCategory = subCategoryToCategoryMap[selectedSubCategoryVal];
    if (relatedCategory) {
      setSelectedCategory(relatedCategory);
    }
  };

  const handleSearchButton = () => {
    trackAmplitudeEvent(AmplitudeEvent.ctaInteracted, {
      cta_type: 'button',
      cta_text: 'Search',
    });

    let url = '/explore/d/activities';
    if (selectedSubCategory) {
      url = selectedSubCategory;
    } else if (selectedCategory) {
      url = `/explore/d/${selectedCategory.replace(/_/g, '-').toLowerCase()}`;
    }

    if (zipCodeValue) {
      url += `?zipCode=${zipCodeValue}&_qs=1`;
    }

    router.push(url, undefined, { shallow: false });
  };

  const onZipCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const zipCodeVal = e.target.value;

    if (!ZipCodeRegex.test(zipCodeVal)) {
      return;
    }
    setZip((prevValues) => ({
      ...prevValues,
      zipCode: zipCodeVal,
    }));
  };

  const onFilterOpen = () => {
    trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, {
      feature_name: 'Filter Viewed',
    });
  };

  useEffect(() => {
    if (activityCategoryLinkSections?.linkSections) {
      const orderedCategories = activityCategoryLinkSections.linkSections.map((linkSection) => ({
        id: linkSection.id,
        title: linkSection.title,
        subCategories: orderBy(
          linkSection.linkList.map((link) => {
            const url = new URL(link.url);
            return {
              name: link.content,
              url: url.pathname,
            };
          }),
          ['name'],
          ['asc']
        ),
      }));

      const sortedCategories = orderBy(orderedCategories, (category) =>
        category.title.toLowerCase()
      );

      setCategoryOptions(sortedCategories);

      const allSubCategories = orderBy(
        orderedCategories.flatMap((category) => category.subCategories),
        ['name'],
        ['asc']
      );
      setSubCategoryOptions(allSubCategories);

      const subCategoryMap: { [key: string]: string } = {};
      orderedCategories.forEach((category) => {
        category.subCategories.forEach((subCategory) => {
          subCategoryMap[subCategory.url] = category.id;
        });
      });

      setSubCategoryToCategoryMap(subCategoryMap);

      setSelectedCategory('');
      setSelectedSubCategory('');
    }
  }, [activityCategoryLinkSections]);

  useEffect(() => {
    if (zipInputRef.current) {
      zipInputRef.current.setSelectionRange(
        zipInputRef.current.value.length,
        zipInputRef.current.value.length
      );

      zipInputRef.current.focus();
    }
  }, [zip]);

  useEffect(() => {
    if (selectedCategory || selectedSubCategory) {
      trackAmplitudeEvent(AmplitudeEvent.seoFeatureInteracted, {
        feature_name: 'Filter Applied',
        location: zip.zipCode,
        categories: selectedCategory,
        subcategories:
          subCategoryOptions.find(({ url }) => url === selectedSubCategory)?.name ?? '',
      });
    }
  }, [zip.zipCode, selectedCategory, selectedSubCategory, subCategoryOptions]);

  const CategorySelect = (
    <FormControl variant="standard" sx={classes.inputSelection}>
      <Select
        size="medium"
        displayEmpty
        value={selectedCategory}
        sx={classes.selectionOne}
        IconComponent={Icon24UtilityChevron}
        inputProps={{
          name: 'CategorySelect',
        }}
        onOpen={onFilterOpen}
        onChange={handleCategoryChange}>
        <MenuItem key="select-category" value="" disabled>
          Select category
        </MenuItem>
        {categoryOptions.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {ACTIVITY_CATEGORY_TITLES[category.id]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const SubCategorySelect = (
    <FormControl variant="standard" sx={classes.inputSelection}>
      <Select
        size="medium"
        displayEmpty
        sx={classes.selectionTwo}
        value={selectedSubCategory}
        IconComponent={Icon24UtilityChevron}
        inputProps={{
          name: 'SubCategorySelect',
        }}
        onOpen={onFilterOpen}
        onChange={handleSubCategoryChange}>
        <MenuItem key="sub-categories" value="" disabled>
          Subcategories
        </MenuItem>
        {subCategoryOptions.map((subCategory) => (
          <MenuItem key={subCategory.url} value={subCategory.url}>
            {capitalizeFirstLetter(subCategory.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const LocationInput = (
    <TextField
      id="zipCode"
      name="zipCode"
      inputRef={zipInputRef}
      placeholder="ZIP code"
      value={zip.zipCode}
      onChange={onZipCodeChange}
      onBlur={(event) => setZipCodeValue(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={classes.positionStart}>
            <Icon24InfoLocation />
          </InputAdornment>
        ),
      }}
      sx={classes.selectionThree}
    />
  );

  const SearchActivity = (
    <TextField
      id="activitySearch"
      placeholder="Search for an activity"
      sx={classes.searchActivity}
      onClick={handleOpenModal}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={classes.positionStart}>
            <Icon24UtilitySearch />
          </InputAdornment>
        ),
        readOnly: true,
      }}
    />
  );

  const SearchButton = (
    <Button
      color="primary"
      variant="contained"
      size="medium"
      sx={classes.searchButton}
      onClick={handleSearchButton}>
      Search
    </Button>
  );

  const MobileView = (
    <Grid container direction="column" spacing={2}>
      <Grid item xs>
        {SearchActivity}
      </Grid>
      <Grid item xs>
        <Grid container justifyContent="center">
          {SearchButton}
        </Grid>
      </Grid>
    </Grid>
  );

  const DesktopView = (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={10}>
        <Grid container alignItems="center" spacing={0}>
          <Grid item xs>
            {CategorySelect}
          </Grid>
          <Grid item xs>
            {SubCategorySelect}
          </Grid>
          <Grid item xs>
            {LocationInput}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        {SearchButton}
      </Grid>
    </Grid>
  );

  return (
    <>
      {isSmallViewport || isMediumViewport ? MobileView : DesktopView}

      <Modal
        open={openActivitySearchModal}
        hideClose
        aria-labelledby="camps-and-activities-search-modal"
        variant="dynamic">
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Button
              sx={(theme) => ({
                color: theme.palette.care?.navy[900],
              })}
              onClick={handleCloseModal}
              size="small"
              startIcon={<Icon24UtilityChevronLeft />}
              variant="text">
              Go back
            </Button>
          </Grid>
          <Grid item xs>
            {CategorySelect}
          </Grid>
          <Grid item xs>
            {SubCategorySelect}
          </Grid>
          <Grid item xs>
            {LocationInput}
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="center">
              {SearchButton}
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CampsAndActivitiesSearch;
