import { useMediaQuery, Theme } from '@mui/material';

function useResponsive() {
  const isSmallViewport = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')); // Mobile Devices
  const isMediumViewport = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'lg')); // Large Mobile and Tablets
  const isLargeViewport = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg')); // Small and Large Desktops

  return {
    isSmallViewport,
    isMediumViewport,
    isLargeViewport,
  };
}

export { useResponsive };
