import getConfig from 'next/config';
import Head from 'next/head';
import { SEOSegmentationPageType } from '@/__generated__/globalTypes';
import { usePageAttributes } from './contexts/PageAttributesContext';

const {
  publicRuntimeConfig: { NEXT_PUBLIC_APP_URL },
} = getConfig();

interface PageMetaProps {
  hrefLangLinks?: HrefLangProps[];
  title: string;
  canonicalUrl: string;
  metaDescriptions: string;
  metaKeywords?: string;
  paginationPageCount?: number;
  paginationCurrentPage?: number;
  isCostGuidePage?: boolean;
}

interface HrefLangProps {
  languageCode: string;
  countryCode: string;
  url: string;
}

const PageMeta = ({
  hrefLangLinks,
  title,
  canonicalUrl,
  metaDescriptions,
  metaKeywords,
  paginationPageCount = 1,
  paginationCurrentPage = 1,
  isCostGuidePage,
}: PageMetaProps) => {
  const { segmentNamePlural, pageType, city, state } = usePageAttributes();
  const logoPath = `${NEXT_PUBLIC_APP_URL}/img/care-no-tag.jpg`;
  let prevLink;
  let nextLink;
  let canonicalPageUrl;
  let newTitle;

  const isLocalPaginatedPage =
    pageType === SEOSegmentationPageType.LOCAL && paginationCurrentPage > 1;

  const showLoadMore = pageType === SEOSegmentationPageType.NATIONAL;

  if (showLoadMore) {
    newTitle = title;
    canonicalPageUrl = canonicalUrl;
  } else if (paginationCurrentPage > 1) {
    if (pageType === SEOSegmentationPageType.LOCAL) {
      newTitle = `Page ${paginationCurrentPage} - Top ${segmentNamePlural} in ${city}, ${state}`;
    } else {
      newTitle = `Page ${paginationCurrentPage} - Top ${segmentNamePlural}`;
    }

    canonicalPageUrl = `${canonicalUrl}-page${paginationCurrentPage}`;
    if (paginationCurrentPage < paginationPageCount) {
      nextLink = `${canonicalUrl}-page${paginationCurrentPage + 1}`;
      prevLink =
        paginationCurrentPage === 2
          ? canonicalUrl
          : `${canonicalUrl}-page${paginationCurrentPage - 1}`;
    } else {
      prevLink = `${canonicalUrl}`;
    }
  } else if (paginationCurrentPage === 1) {
    newTitle = title;
    canonicalPageUrl = canonicalUrl;
    if (paginationPageCount > 1) {
      nextLink = `${canonicalUrl}-page2`;
    }
  }

  return (
    <Head>
      {hrefLangLinks &&
        hrefLangLinks.map(({ languageCode, countryCode, url }) => (
          <link key={url} rel="alternate" href={url} hrefLang={`${languageCode}-${countryCode}`} />
        ))}

      <title>{newTitle}</title>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="description" content={metaDescriptions} />
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {isLocalPaginatedPage && <meta name="robots" content="noindex, follow" />}
      <link rel="canonical" href={canonicalPageUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="verify-v1" content="zz15lR180u9eCqTfur/9l+bMP75AampYtDEm6OnbkWY=" />
      <meta property="og:title" content={newTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={logoPath} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:description" content={metaDescriptions} />
      <meta property="og:site_name" content="Care.com" />
      <meta content="image/jpeg" property="og:image:type" />
      <meta content="1080" property="og:image:width" />
      <meta content="1080" property="og:image:height" />
      <link href="/img/care-no-tag.jpg" />
      <meta property="fb:page_id" content="6030284861" />
      {isCostGuidePage && (
        <link rel="shortcut icon" href="https://www.care.com/img/crcm-favicon.ico" />
      )}

      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            logo: logoPath,
            name: 'Care.com',
            sameAs: [
              'https://www.facebook.com/caredotcom/',
              'https://twitter.com/CareDotCom',
              'https://www.instagram.com/careorg/',
              'https://www.linkedin.com/company/care-com',
            ],
            url: 'https://www.care.com',
          }),
        }}
      />

      {!isCostGuidePage && nextLink && <link rel="next" href={nextLink} />}
      {!isCostGuidePage && prevLink && <link rel="prev" href={prevLink} />}
    </Head>
  );
};

export default PageMeta;
