/*
 * date conversion to one of the following example formats:
 * Apr 2023
 * April 2023
 * Apr 25, 2023
 * April 25, 2023
 * Apr 25th, 2023
 * April 25th, 2023
 */

// Add postfix like 3rd, 4th, 22nd to the date
const getOrdinalNum = (n: number) => {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
};

type DateMonthYearParams = {
  dateTime: string | number | Date;
  useLongMonth?: boolean;
  useOrdinalDay?: boolean;
  useFullDate?: boolean;
};

const dateMonthYearFormat = ({
  dateTime,
  useLongMonth = false,
  useOrdinalDay = false,
  useFullDate = false,
}: DateMonthYearParams) => {
  const date = new Date(dateTime);
  const dayOftheMonth = useOrdinalDay ? `${getOrdinalNum(date.getUTCDate())}` : date.getUTCDate();
  const month = useLongMonth
    ? date.toLocaleString('en-US', { month: 'long' })
    : date.toLocaleString('en-US', { month: 'short' });

  const year = date.getUTCFullYear();

  return useFullDate ? `${month} ${dayOftheMonth}, ${year}` : `${month} ${year}`;
};

export default dateMonthYearFormat;
