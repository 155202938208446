import { Box, Typography } from '@mui/material';

const HeroContent = () => {
  return (
    <Box px={{ xs: 0, sm: 6, lg: 9 }} pt={{ xs: 1, sm: 2, md: 6, lg: 8 }}>
      <Box>
        <Typography
          variant="h1"
          fontSize={{ md: 'display2.fontSize' }}
          sx={(theme) => ({
            lineHeight: { md: theme.typography.display2.lineHeight },
          })}>
          Find kids activities and camps&mdash;
          <br />
          without the headaches
        </Typography>
      </Box>
      <Box py={{ xs: 2, sm: 3, lg: 4 }}>
        <Typography variant="body1" fontSize={{ md: 'h2.fontSize' }}>
          Browse, compare, and book classes, after-school programs, and mores&mdash;all in one
          place.
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroContent;
