import { Box, Grid } from '@mui/material';
import ArticleCarousel from '@/components/ArticleCarousel';

interface ArticlesProps {
  seoArticlesList: any;
}

const Articles = ({ seoArticlesList }: ArticlesProps) => {
  const articles = seoArticlesList?.articles ?? null;

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 4 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {articles && (
        <Grid container maxWidth="lg">
          <Grid item xs={12}>
            <ArticleCarousel articles={articles} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Articles;
