import { Box, Grid, Typography, List, ListItem } from '@mui/material';
import { Icon24UtilityCheckmarkLarge } from '@care/react-icons';
import getConfig from 'next/config';
import { SxClassProps } from '@/types/common';
import { useResponsive } from '../../hooks/useResponsive';

const {
  publicRuntimeConfig: { NEXT_ASSET_URL },
} = getConfig();

const SEASONALITY_CONTENT = [
  {
    headline: "For when school's out for summer",
    subHeadline:
      'Discover full-day sports camps or week-long programs over the dog days of summer.',
  },
  {
    headline: 'For when school takes a break',
    subHeadline:
      'Keep kids engaged over spring or winter break with short-term art classes, STEM camps, and more.',
  },
  {
    headline: 'For when school is back in session',
    subHeadline:
      'Help your kid get back into the swing of things with after-school enrichment programs.',
  },
];

const classes: SxClassProps = {
  container: {
    width: '100%',
    margin: 'auto',
    marginTop: {
      xs: 0,
      md: 8,
    },
    marginBottom: {
      xs: 1,
      md: 8,
    },
    borderRadius: {
      xs: '0 0 64px 64px',
      sm: '0 0 120px 120px',
    },
    backgroundColor: '#FAF7F7',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    flexWrap: 'nowrap',
  },
  imageContainer: (theme) => ({
    height: {
      xs: '285px',
      sm: '380px',
      md: '650px',
    },
    width: {
      xs: '100%',
      md: '45%',
    },
    backgroundImage: {
      xs: `url(${NEXT_ASSET_URL}/images/seasonality-mw.jpg?im=)`,
      md: `url(${NEXT_ASSET_URL}/images/seasonality-dt.jpg?im=)`,
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: {
      xs: 'none',
      md: '0 0 0 120px',
    },
    overflow: 'hidden',
    paddingRight: 0,
    paddingBottom: '37px',
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 0,
      paddingRight: '189px',
    },
  }),
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingX: 3,
    marginTop: { xs: 3, sm: 4, md: 6 },
    marginBottom: { xs: 6, sm: 8, md: 5 },
    maxWidth: { xs: '500px', sm: 'none' },
    boxSizing: { xs: 'content-box', sm: 'border-box' },
    marginX: 'auto',
  },
  content: {
    width: 'auto',
    '> *': {
      maxWidth: { xs: 'none', sm: '560px', md: '500px' },
    },
  },
  header: (theme) => ({
    marginBottom: { sm: 1, md: 4 },
    marginTop: { xs: 2, sm: 2, md: 8 },
    lineHeight: { xs: '42px', lg: '34px' },
    minWidth: '237px',
    maxWidth: '300px',
    '& strong': {
      boxDecorationBreak: 'clone',
      backgroundColor: theme.palette.care?.blue[100],
      padding: { xs: '2px', sm: '4px', md: '4px' },
      borderRadius: '8px',
      lineHeight: { sm: '42px', md: '60px' },
    },
    fontSize: 'h1.fontSize',
    [theme.breakpoints.up(470)]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.up(834)]: {
      minWidth: '420px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 'initial',
      fontSize: 'display3.fontSize',
    },
  }),
  list: {
    marginTop: { xs: 3, md: 4 },
  },
  subHeading: {
    fontSize: { xs: 'h3.fontSize', sm: 'h2.fontSize' },
    paddingTop: { xs: 2, md: 3 },
    fontWeight: 400,
    lineHeight: '24px',
    paddingRight: { md: '50px' },
  },
  iconWrapper: {
    flexShrink: 0,
    marginRight: 2,
  },
};

function Seasonality() {
  const { isSmallViewport } = useResponsive();

  return (
    <Grid container sx={classes.container}>
      <Grid container item sx={classes.imageContainer} direction="row" />
      <Box sx={classes.contentContainer}>
        <Grid container item sx={classes.content} direction="column">
          <Typography variant="h2" sx={classes.header}>
            <strong>Engaging activities</strong> for
            <br />
            every season
          </Typography>

          <Typography variant="h3" sx={classes.subHeading}>
            Whether you&apos;re looking at summer vacation, spring break, or those after-school care
            gaps—we have you covered all year round.
          </Typography>
          <List sx={classes.list}>
            {SEASONALITY_CONTENT.map((item, index) => (
              <ListItem key={`point-${index + 1}`} disableGutters alignItems="flex-start">
                <Box sx={classes.iconWrapper}>
                  <Icon24UtilityCheckmarkLarge width={22} height={22} />
                </Box>

                <Box>
                  <Typography variant={!isSmallViewport ? 'h2' : 'h3'} mb={1}>
                    {item.headline}
                  </Typography>
                  <Typography variant="body2" mb={1}>
                    {item.subHeadline}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Seasonality;
