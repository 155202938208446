import { useEffect } from 'react';
import { CoreGlobalFooter } from '@care/navigation';
import { Grid } from '@mui/material';

import PageMeta from '@/components/PageMeta';
import NavBar from '@/components/SeoGlobalNavbar';
import { seoArticlesList_seoArticlesList_SEOArticlesListSuccess as SEOArticlesList } from '@/__generated__/seoArticlesList';
import { seoActivityCategoryLinkSections_seoActivityCategoryLinkSections_SEOActivityCategoryLinkSectionsSuccess as SEOActivityCategoryLinkSections } from '@/__generated__/seoActivityCategoryLinkSections';
import {
  AmplitudeEvent,
  trackAmplitudeEvent,
  setDeviceDataFromHeaders,
  setPagePropertiesFromData,
} from '@/lib/analyticsHelper';

import HeroSection from './HeroSection';
import CategoryTiles from './CategoryTiles';
import ValueProp from './ValueProp';
import Seasonality from './Seasonality';
import Articles from './Articles';
import InternalLinks from './InternalLinks';

export type LandingPageProps = {
  deviceChar: {
    device_os: string;
    is_mobile: string;
    mobile_browser_version: string;
    mobile_browser: string;
  };
  userGeoInfo: string;
  activityCategoryLinkSections: SEOActivityCategoryLinkSections | null;
  seoArticlesListData: SEOArticlesList | null;
};

const LandingPage = ({
  deviceChar,
  userGeoInfo,
  activityCategoryLinkSections,
  seoArticlesListData,
}: LandingPageProps) => {
  const metaTitle = 'Explore: kids activities, camps and classes - Care.com';
  const metaDescription = 'Book the best kids activities, camps and classes from local providers';
  const canonicalUrl = 'https://www.care.com/explore';

  useEffect(() => {
    const pageProperties = {
      seo_page_id: 1539,
      screen_name: 'Explore landing page',
      screen_template: 'Explore landing page',
    };
    if (deviceChar && Object.keys(deviceChar).length > 0) {
      setPagePropertiesFromData(pageProperties);
      setDeviceDataFromHeaders(deviceChar);
    }
  }, [deviceChar]);

  useEffect(() => {
    trackAmplitudeEvent(AmplitudeEvent.seoPageViewed, {
      seo_page_type: 'Explore landing page',
      page_template: 'Explore landing page',
      seo_page_title: 'Find kids activities and camps— without the headaches',
    });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <PageMeta
          title={metaTitle}
          metaDescriptions={metaDescription}
          canonicalUrl={canonicalUrl}
        />
        <NavBar enrollmentURL="/app/vhp/get-started" />
        <HeroSection
          userGeoInfo={userGeoInfo}
          activityCategoryLinkSections={activityCategoryLinkSections}
        />
        <CategoryTiles />
        <ValueProp />
        <Seasonality />
        <Articles seoArticlesList={seoArticlesListData} />
        <InternalLinks activityCategoryLinkSections={activityCategoryLinkSections} />
        <CoreGlobalFooter />
      </Grid>
    </Grid>
  );
};

export default LandingPage;
