import { useMemo } from 'react';
import { Icon24UtilityChevron } from '@care/react-icons';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  Link,
  Typography,
  Box,
  Theme,
  Grid,
} from '@mui/material';
import { orderBy, upperFirst } from 'lodash-es';
import he from 'he';
import sanitizeHtml from 'sanitize-html';
import { seoActivityCategoryLinkSections_seoActivityCategoryLinkSections_SEOActivityCategoryLinkSectionsSuccess as SEOActivityCategoryLinkSections } from '@/__generated__/seoActivityCategoryLinkSections';
import { ACTIVITY_CATEGORY_TITLES } from '@/constants';
import { useResponsive } from '../../hooks/useResponsive';

const classes = {
  linkCategories: {
    cursor: 'pointer',
    lineHeight: '30px',
  },
  linkCategoriesContainer: (theme: Theme) => ({
    [theme.breakpoints.up('sm')]: {
      minHeight: '200px',
    },
  }),
  flexContainer: (theme: Theme) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  }),
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  accordionSummary: {
    width: '100%',
    padding: 0,
    '& .MuiIconButton-root': {
      marginRight: '-7px',
    },
    minHeight: '48px',
  },
  sectionLinks: (theme: Theme) => ({
    cursor: 'pointer',
    color: `${theme.palette.care.navy[900]} !important`,
    lineHeight: '40px',
    textDecoration: 'underline',
  }),
};

interface LinkList {
  address: string;
  label: string;
}

interface InternalLinksProps {
  activityCategoryLinkSections: SEOActivityCategoryLinkSections | null;
}

const InternalLinks = ({ activityCategoryLinkSections }: InternalLinksProps) => {
  const { isSmallViewport } = useResponsive();

  const links = (linkList: LinkList[]) => {
    const sortedLinks = orderBy(linkList, ['label'], ['asc']);
    return sortedLinks.map((link: LinkList) => (
      <Link
        variant={isSmallViewport ? 'link2' : 'link1'}
        sx={classes.sectionLinks}
        href={link.address}
        key={link.label}>
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(he.decode(upperFirst(link.label))),
          }}
        />
      </Link>
    ));
  };

  const sortedSections = useMemo(() => {
    const transformLinksData = () => {
      if (!activityCategoryLinkSections) return [];

      return activityCategoryLinkSections.linkSections.map((section) => ({
        label: section.id,
        items: section.linkList.map((link) => ({
          address: link.url,
          label: link.content,
        })),
      }));
    };

    return orderBy(transformLinksData(), ['label'], ['asc']);
  }, [activityCategoryLinkSections]);

  if (isSmallViewport) {
    return (
      <Container
        sx={() => ({
          backgroundColor: '#FAF7F7',
          px: 3,
          py: 4,
          mt: 4,
        })}>
        <Grid maxWidth="md" item xs={12}>
          <Typography variant="h1" pb={2}>
            Explore all categories
          </Typography>
          <Accordion defaultExpanded elevation={0}>
            <AccordionDetails sx={{ m: 0, p: 0 }}>
              {sortedSections.map((tabSection) => (
                <Accordion key={tabSection.label} elevation={0}>
                  <AccordionSummary
                    sx={classes.accordionSummary}
                    expandIcon={<Icon24UtilityChevron color="#172C42" />}>
                    <Typography variant="h3">
                      {ACTIVITY_CATEGORY_TITLES[tabSection.label]}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ m: 0, p: 0 }}>
                    <Box sx={classes.flexContainer}>
                      <Box sx={classes.listContainer}>{links(tabSection.items)}</Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: '#FAF7F7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Container maxWidth="lg" sx={{ pt: 2, pb: 6 }}>
        <Grid item xs={12}>
          {sortedSections.length > 0 && (
            <>
              <Typography
                variant="h2"
                fontSize="display3.fontSize"
                textAlign="center"
                pt={5}
                pb={8}
                px={0}>
                Explore all categories
              </Typography>
              <Box sx={classes.linkCategoriesContainer}>
                <Grid container spacing={4}>
                  {sortedSections.map((linkSection) => (
                    <Grid item xs={12} sm={3} key={linkSection.label}>
                      <Box sx={classes.linkCategories}>
                        <Typography variant="h2" sx={{ pb: 2 }}>
                          {ACTIVITY_CATEGORY_TITLES[linkSection.label]}
                        </Typography>
                        <Box sx={classes.flexContainer}>
                          <Box sx={classes.listContainer}>{links(linkSection.items)}</Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default InternalLinks;
