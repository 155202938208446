import { Box } from '@mui/material';
import { Card } from '@care/react-component-lib';
import getConfig from 'next/config';
import { seoActivityCategoryLinkSections_seoActivityCategoryLinkSections_SEOActivityCategoryLinkSectionsSuccess as SEOActivityCategoryLinkSections } from '@/__generated__/seoActivityCategoryLinkSections';
import HeroContent from './HeroContent';
import CampsAndActivitiesSearch from './CampsAndActivitiesSearch';

const {
  publicRuntimeConfig: { NEXT_ASSET_URL },
} = getConfig();

interface HeroSectionProps {
  userGeoInfo?: string;
  activityCategoryLinkSections: SEOActivityCategoryLinkSections | null;
}

const HeroSection = ({ userGeoInfo, activityCategoryLinkSections }: HeroSectionProps) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundImage: {
          xs: 'none',
          md: `url(${NEXT_ASSET_URL}/images/campsAndActivities-hero.jpg?im=)`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'top',
      }}>
      <Box
        px={{ xs: 3, sm: 5, lg: 0 }}
        py={{ xs: 2, sm: 2, md: 7 }}
        sx={{
          width: '100%',
          maxWidth: '1080px',
          margin: '0 auto',
        }}>
        <Card
          careVariant="subtle"
          sx={{
            borderRadius: {
              xs: '0',
              sm: '0',
              md: '16px',
            },
            boxShadow: {
              xs: 'none',
              sm: 'none',
              md: '0 0 16px rgba(0, 0, 0, 0.15)',
            },
            width: '100%',
            maxWidth: '100%',
          }}>
          <HeroContent />
          <Box px={{ xs: 0, sm: 6, lg: 9 }} pb={{ xs: 1, sm: 1, md: 6, lg: 7 }}>
            <CampsAndActivitiesSearch
              userGeoInfo={userGeoInfo ?? ''}
              activityCategoryLinkSections={activityCategoryLinkSections}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default HeroSection;
