import { Box, Typography, List, ListItem } from '@mui/material';
import {
  Icon24UtilitySearch,
  Icon24UtilityRefresh,
  Icon24InfoResources,
  Icon24InfoStar,
} from '@care/react-icons';
import getConfig from 'next/config';
import { SxClassProps } from '@/types/common';
import { useResponsive } from '../../hooks/useResponsive';

const {
  publicRuntimeConfig: { NEXT_ASSET_URL },
} = getConfig();

const classes: SxClassProps = {
  container: {
    paddingTop: {
      xs: 0,
      sm: 12,
      md: 8,
    },
    paddingBottom: {
      xs: 3,
      sm: 12,
      md: 8,
    },
    paddingX: {
      xs: 3,
      sm: 5,
      md: 4,
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: {
      xs: '500px',
      sm: 'none',
    },
    boxSizing: 'content-box',
    marginX: 'auto',
  },

  wrapper: {
    flexDirection: {
      xs: 'column-reverse',
      sm: 'row',
      md: 'row',
    },
    gap: {
      xs: 4,
      sm: 11,
      md: 11,
    },

    height: {
      sm: '357px',
      md: '408px',
    },
    background: 'white',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1062px',
  },

  valuePropSection: {
    width: {
      xs: '100%',
      sm: '377px',
      md: '554px',
    },
  },
  header: (theme) => ({
    marginBottom: 2,
    lineHeight: {
      xs: '42px',
      lg: '34px',
    },

    minWidth: '237px',
    maxWidth: '300px',
    '& strong': {
      boxDecorationBreak: 'clone',
      backgroundColor: theme.palette.care?.blue[100],
      padding: {
        xs: '2px',
        sm: '4px',
        md: '4px',
      },
      borderRadius: '8px',
      lineHeight: {
        sm: '42px',
        md: '60px',
      },
    },

    fontSize: 'h1.fontSize',
    [theme.breakpoints.up(470)]: {
      maxWidth: 'initial',
    },
    [theme.breakpoints.up(834)]: {
      minWidth: '420px',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 'initial',
      fontSize: 'display3.fontSize',
    },
  }),
  subheader: {
    fontSize: {
      xs: 'h3.fontSize',
      sm: 'h2.fontSize',
    },
    lineHeight: '26px',
    maxWidth: {
      xl: '544px',
    },
    paddingRight: {
      xs: 0,
      md: '21%',
      lg: 0,
    },
  },
  list: {
    width: {
      sm: '450px',
      md: '100%',
    },
    marginBottom: {
      xs: '27px',
      sm: '32px',
    },
    '> .MuiListItem-root': {
      marginBottom: '32px',
    },
    'li:first-child p:first-child': {
      paddingRight: {
        xs: '6px',
      },
    },
    '& li': {
      paddingY: '0',
    },
  },

  iconWrapper: {
    flexShrink: 0,
    marginRight: {
      xs: 2,
      sm: 4,
    },
    width: {
      xs: '40px',
      md: '48px',
    },
    height: {
      xs: '40px',
      md: '48px',
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  imageWrapper: {
    borderRadius: '16px',
    border: '1px solid #fff',
    overflow: 'hidden',
    width: {
      xs: '100%',
    },
    maxWidth: {
      sm: '327px',
      md: '519px',
    },
    flexBasis: {
      sm: '40%',
    },
  },
  imageContainer: {
    height: {
      xs: '277px',
      sm: '401px',
      md: '490px',
    },
    width: {
      sm: '100%',
    },
    maxWidth: '100%',
    backgroundImage: {
      xs: `url(${NEXT_ASSET_URL}/images/value-prop-mw.jpg?im=)`,
      sm: `url(${NEXT_ASSET_URL}/images/value-prop-dt.jpg?im=)`,
    },
    backgroundPosition: {
      xs: 'center',
      sm: '30%',
      md: '40%',
      lg: 'center',
    },
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: {
      sm: 'care.white',
      md: '#FAF7F7',
    },
  },
};

const VALUE_PROP_CONTENT = [
  {
    icon: <Icon24UtilitySearch />,
    headline: 'Effortless booking',
    subHeadline: 'Compare, choose, and book the activities you love—all with just a few clicks.',
  },
  {
    icon: <Icon24UtilityRefresh size={48} />,
    headline: 'Personalized to you',
    subHeadline:
      'Advanced search options make it easy to match programs with you and your child’s preferences.',
  },
  {
    icon: <Icon24InfoResources size={48} />,
    headline: 'Reviews you can trust',
    subHeadline:
      'With ratings and reviews from parents like you, you can feel confident in your choice.',
  },
  {
    icon: <Icon24InfoStar size={48} />,
    headline: 'All needs, one place',
    subHeadline:
      'From nannies and sitters to camps and activities, your entire childcare plan can stay here.',
  },
];

function ValueProp() {
  const { isSmallViewport } = useResponsive();
  return (
    <Box sx={classes.container}>
      <Box sx={classes.wrapper}>
        <Box sx={classes.valuePropSection}>
          <Typography variant="h1" color="textPrimary" sx={classes.header}>
            Easy to enjoy, <strong>easier to book</strong>
          </Typography>
          <List sx={classes.list}>
            {VALUE_PROP_CONTENT.map((item) => (
              <ListItem disableGutters alignItems="flex-start" key={item.headline}>
                <Box sx={classes.iconWrapper}>{item.icon}</Box>
                <Box>
                  <Typography variant={!isSmallViewport ? 'h2' : 'h3'} mb={1}>
                    {item.headline}
                  </Typography>
                  <Typography variant="body2">{item.subHeadline}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={classes.imageWrapper}>
          <Box sx={classes.imageContainer} data-testid="value-prop-image" />
        </Box>
      </Box>
    </Box>
  );
}

export default ValueProp;
